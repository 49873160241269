import { Box, BoxProps, CircularProgress } from "@mui/material";
import { useWebgate } from "@xvia/webgate-connect";
import { useEffect, useState } from "react";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { getLogoVehicleMark } from "../../apis/vehicle-logo";

interface ContainerProps extends Omit<BoxProps, "children"> {
  readonly children: React.ReactNode;
  readonly component?: React.ElementType;
}

type VehicleMarkLogoProps = {
  readonly mark: string;
  readonly type: number;
};

export function Container({
  children,
  component = "div",
  ...props
}: ContainerProps) {
  return (
    <Box
      component={component}
      sx={(theme) => ({
        width: "5rem",
        [theme.breakpoints.down("lg")]: {
          width: "4rem",
        },
      })}
      {...props}
    >
      {children}
    </Box>
  );
}

export function VehicleMarkLogo({ mark, type }: VehicleMarkLogoProps) {
  const { accessToken } = useWebgate();

  const [logo, setLogo] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!accessToken) return;

    fetchVehicleMarkLogo(mark, type);
  }, [accessToken, mark, type]);

  async function fetchVehicleMarkLogo(mark: string, type: number) {
    try {
      if (!loading) setLoading(true);

      const response = await getLogoVehicleMark(accessToken!, mark, type);

      if (response?.url) {
        setLogo(response.url);
      } else {
        setLogo(null);
      }
    } catch (e) {
      setLogo(null);
    } finally {
      setLoading(false);
    }
  }

  if (loading && !logo)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );

  return !!logo ? (
    <Container>
      <img
        src={logo}
        alt={`${mark} Logo`}
        style={{ width: "40px", height: "auto" }}
      />
    </Container>
  ) : (
    <Container>
      <DirectionsCarIcon sx={{ fontSize: "40px", color: "#034EA2" }} />
    </Container>
  );
}
