import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { FC, MouseEventHandler } from "react";
import { ServiceLevelBadge } from "../../service-level-badge/service-level-badge";

import { useWebgate } from "@xvia/webgate-connect";
import { setFavorites } from "../../../apis/dashboard";
import "./service-card.css";

export interface ServiceCardProps {
  title: string;
  description: string;
  categoryIcon: string;
  rating?: number;
  imageUrl?: string;
  isOnline?: boolean;
  focused?: boolean;
  isFavorite?: boolean;
  type?: "online" | "in-person";
  targets?: string[];
  onClick: any;
  onClickFavorite?: MouseEventHandler;
  setSeverityAlert?: (severity: "success" | "error") => void;
  setOpenAlert?: (open: boolean) => void;
  setMessageAlert?: (message: string) => void;
  accessProfile?: string;
  slug?: string;
}

export const ServiceCard: FC<ServiceCardProps> = ({
  title,
  focused,
  imageUrl,
  categoryIcon,
  onClick,
  description,
  accessProfile,
  isFavorite,
  onClickFavorite,
  setSeverityAlert,
  setOpenAlert,
  setMessageAlert,
  slug,
}) => {
  const containerClassName = classNames({
    "xvia-service-card": true,
    "xvia-service-card---focused": focused,
  });

  const { accessToken, userInfo } = useWebgate();
  const handleFavoriteClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await setFavorites(accessToken, {
      slug,
      remove: isFavorite ?? false,
    })
      .then(() => {
        setSeverityAlert?.("success");
        setOpenAlert?.(true);
        setMessageAlert?.(
          isFavorite
            ? "Serviço removido dos favoritos"
            : "Serviço favoritado com sucesso"
        );
        onClickFavorite?.(e);
      })
      .catch(() => {
        setSeverityAlert?.("error");
        setOpenAlert?.(true);
        setMessageAlert?.("Erro ao favoritar serviço");
      });
  };

  return (
    <button aria-label={title} onClick={onClick} className={containerClassName}>
      {imageUrl ? (
        <div className="xvia-service-card__thumb">
          <img src={imageUrl} alt="thumb" />
        </div>
      ) : null}
      <div className="xvia-service-card__content">
        <div className="xvia-service-card__header">
          <div className="xvia-service-card__icon">
            <FontAwesomeIcon icon={`${categoryIcon}` as IconProp} />
          </div>

          <div className="xvia-service-card__header-right">
            <div className="xvia-service-card__badge-container">
              {userInfo && (
                <button
                  onClick={handleFavoriteClick}
                  className={`xvia-service-card__favorite ${
                    isFavorite ? "is-favorite" : ""
                  }`}
                  aria-label={
                    isFavorite
                      ? "Remover dos favoritos"
                      : "Adicionar aos favoritos"
                  }
                >
                  <FontAwesomeIcon icon="star" />
                </button>
              )}
              {!!accessProfile && (
                <div className="xvia-service-card__badge">
                  <ServiceLevelBadge level={accessProfile} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="xvia-service-card__title-wrapper">
          <Typography variant="h5" className="xvia-service-card__title">
            {title}
          </Typography>
        </div>
        <Typography variant="body1" className="xvia-service-card__sub-title">
          {description}
        </Typography>
      </div>
    </button>
  );
};
