import { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Catalog } from "../../apis/search-catalog";
import { FavoriteServices, FavoriteServicesProps } from "./favorite-services";

export interface FavoriteServicesContainerProps {
  allCatalogList?: Catalog[];
  favoriteList?: string[];
  onFavoriteUpdate?: (slug: string, remove: boolean) => void;
  setMessageAlert?: (message: string) => void;
  setSeverityAlert?: (severity: "success" | "error") => void;
  setOpenAlert?: (open: boolean) => void;
}

export const FavoriteServicesContainer: FC<FavoriteServicesContainerProps> = ({
  allCatalogList,
  favoriteList,
  onFavoriteUpdate,
  setMessageAlert,
  setSeverityAlert,
  setOpenAlert,
}) => {
  let history = useHistory();

  const onClickCard = useCallback(
    (catalog: Catalog) => {
      if (catalog.link.startsWith("http")) {
        window.location.href = catalog.link;
      } else if (catalog.link.startsWith("/app/")) {
        window.location.href = catalog.link;
      } else {
        history.push(`${catalog.categorySlug}/${catalog.slug}`);
      }
    },
    [history]
  );

  const handleFavoriteClick = useCallback(
    (slug: string, isFavorite: boolean) => {
      onFavoriteUpdate?.(slug, isFavorite);
    },
    [onFavoriteUpdate]
  );

  const favoriteServicesProps: FavoriteServicesProps = {
    services:
      allCatalogList
        ?.filter((catalog) =>
          favoriteList?.some((favorite) => favorite === catalog.slug)
        )
        ?.map((catalog) => ({
          title: catalog.title,
          description: catalog.description,
          categoryIcon: catalog.icon,
          onClick: () => onClickCard(catalog),
          imageUrl: catalog.imageUrl,
          onClickFavorite: () => handleFavoriteClick(catalog.slug, true),
          type: catalog.isOnline ? "online" : "in-person",
          targets: catalog.targets,
          accessProfile: catalog.accessProfile,
          slug: catalog.slug,
        })) || [],
  };

  return <FavoriteServices {...favoriteServicesProps} />;
};
