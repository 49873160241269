import axios from "axios";

const apiUrl = "/api/govpicidadao-gateway";
export interface BucketPath {
  url: string;
  caminho: string;
}

const axiosCLient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});

export async function getLogoVehicleMark(
  accessToken: string,
  mark: string,
  type: number
): Promise<BucketPath> {
  const response = await axiosCLient.get(`/marca-veiculo/logo/`, {
    params: {
      marca: mark,
      tipoVeiculo: type,
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data;
}
