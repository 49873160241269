import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import Slider, { Settings } from "react-slick";
import "./carousel.css";

export interface CarouselProps {
  children: any;
  slidesToShow?: number;
}

export const Carousel: FC<CarouselProps> = ({ children, slidesToShow = 4 }) => {
  const childrenCount = React.Children.count(children);

  var settings = {
    dots: false,
    infinite: childrenCount > slidesToShow,
    speed: 500,
    slidesToShow: slidesToShow || 4,
    slidesToScroll: 1,
    initialSlide: 0,
    accessibility: true,
    autoplay: childrenCount > slidesToShow,
    variableWidth: false,

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: slidesToShow - 1 || 3,
          slidesToScroll: 1,
          infinite: childrenCount > slidesToShow - 1,
          autoplay: childrenCount > slidesToShow - 1,
        },
      },
      {
        breakpoint: 796,
        settings: {
          slidesToShow: slidesToShow - 2 || 2,
          slidesToScroll: 1,
          arrows: false,
          infinite: childrenCount > slidesToShow - 2,
          autoplay: childrenCount > slidesToShow - 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          arrows: false,
          infinite: childrenCount > 1.2,
          autoplay: childrenCount > 1.2,
        },
      },
    ],
  } as Settings;
  return (
    <Slider
      {...settings}
      className={"xvia-carousel"}
      nextArrow={<FontAwesomeIcon icon={faChevronRight} />}
      prevArrow={<FontAwesomeIcon icon={faChevronLeft} />}
    >
      {children}
    </Slider>
  );
};
