import { Spin } from "antd";
import { FC } from "react";
import {
  ServiceCard,
  ServiceCardProps,
} from "../../components/cards/service-card/service-card";
import { Carousel } from "../../components/carousel/carousel";
import { Container } from "../../components/container/container";
import { SectionTitle } from "../../components/section-title/section-title";

import "./favorite-services.css";

export interface FavoriteServicesProps {
  services?: ServiceCardProps[];
}

export const FavoriteServices: FC<FavoriteServicesProps> = ({ services }) => {
  return services && services.length > 0 ? (
    <div className="xvia-top-services">
      <Container className="xvia-relevant-services__container">
        <SectionTitle text={"Seus favoritos"} />
        <Carousel>
          {services?.map((service) => {
            return (
              <ServiceCard
                key={service.description}
                title={service.title}
                description={service.description}
                categoryIcon={service?.categoryIcon}
                onClick={service.onClick}
                onClickFavorite={service.onClickFavorite}
                rating={service?.rating}
                type={service?.type}
                imageUrl={service?.imageUrl}
                targets={service?.targets}
                isFavorite={true}
                accessProfile={service?.accessProfile}
                slug={service?.slug}
              />
            );
          })}
        </Carousel>
        {!services && (
          <div className={"xvia-relevant-services__loading"}>
            <Spin />
          </div>
        )}
      </Container>
    </div>
  ) : null;
};
