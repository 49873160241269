import axios from "axios";

const apiUrl = process.env.BACKEND_ENDPOINT || "";
const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});
export interface Catalog {
  type: string;
  title: string;
  slug: string;
  description: string;
  tags: string[];
  isFree: boolean;
  isOnline: boolean;
  isDigital?: boolean;
  category: string;
  targets: string[];
  department: string;
  departmentSections: string[];
  categorySlug: string;
  highlighted: boolean;
  score: number;
  imageUrl: string;
  icon: string;
  link: string;
  resultTotal: number;
  resultCategory?: string;
  accessProfile?: string;
  isSelfService?: boolean;
  catalogDataRow: ICatalogDataRaw[];
}

export interface ICatalogDataRaw {
  id: string;
  name: string;
  value: string;
}

export async function search(
  accessToken?: string,
  query?: string,
  category?: string,
  departmentSlug?: string,
  from?: number,
  highlighted?: boolean
): Promise<Catalog[]> {
  const filter = {
    query: query,
    groups: ["CATALOG"],
    category: category,
    departmentSlug: departmentSlug,
    from: from,
  } as any;
  if (highlighted) filter.highlighted = highlighted;
  const response = await axiosClient.post(`/v1/search`, filter, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data;
}
