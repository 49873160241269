import axios from "axios";

const apiUrl = process.env.BACKEND_ENDPOINT || "";

export interface IDashboard {
  title: string;
  value: number;
}

const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});

export async function getDashboard(): Promise<IDashboard[]> {
  const response = await axiosClient.get(`/v1/catalog/dashboard`);

  return response.data;
}

export type Favorite = {
  slug: string | undefined;
  remove: boolean;
};

export async function setFavorites(
  accessToken?: string,
  favoriteList?: Favorite
): Promise<string[]> {
  const response = await axiosClient.post(
    "/v1/catalog/setFavorite",
    favoriteList,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}
