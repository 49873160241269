import { useWebgate } from "@xvia/webgate-connect";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getTopCatalog } from "../../apis/relevant-catalog";
import { Catalog } from "../../apis/search-catalog";
import { getServerData } from "../../util/get-server-data";
import { RelevantServicesProps, TopServices } from "./top-services";
export interface TopServicesContainerProps {
  allCatalogList?: Catalog[];
  favoriteList?: string[];
  onFavoriteUpdate?: (slug: string, remove: boolean) => void;
  setMessageAlert?: (message: string) => void;
  setSeverityAlert?: (severity: "success" | "error") => void;
  setOpenAlert?: (open: boolean) => void;
}

export const TopServicesContainer: FC<TopServicesContainerProps> = ({
  allCatalogList,
  favoriteList,
  onFavoriteUpdate,
  setMessageAlert,
  setSeverityAlert,
  setOpenAlert,
}) => {
  const { accessToken, serverData } = useWebgate();
  let history = useHistory();
  const [relevantCatalogList, setRelevantCatalogList] = useState<Catalog[]>(
    serverData?.top || getServerData("top", null)
  );
  const getServerRelevant = useCallback(() => {
    console.log("4 TopServicesContainer INICIO");
    getTopCatalog(accessToken)
      .then((result) => {
        setRelevantCatalogList(result);
        console.log("4 TopServicesContainer FIM");
      })
      .catch((err) => {
        console.log(err);
        console.log("4 TopServicesContainer ERRO");
      });
  }, [accessToken]);

  const onClickCard = useCallback(
    (categorySlug, slug) => {
      history.push(`${categorySlug}/${slug}`);
    },
    [history]
  );

  const handleFavoriteClick = useCallback(
    (slug: string, isFavorite: boolean) => {
      onFavoriteUpdate?.(slug, isFavorite);
    },
    [onFavoriteUpdate]
  );

  useEffect(() => {
    if (!relevantCatalogList?.length) getServerRelevant();
  }, [getServerRelevant, relevantCatalogList?.length]);

  const relevantServicesProps: RelevantServicesProps = {
    services: relevantCatalogList?.map((catalog) => {
      const isFavorite = favoriteList?.some(
        (favorite) => favorite === catalog.slug
      );
      return {
        title: catalog.title,
        description: catalog.description,
        categoryIcon: catalog.icon,
        onClick: () => {
          onClickCard(catalog.categorySlug, catalog.slug);
        },
        imageUrl: catalog.imageUrl,
        onClickFavourite: () => {},
        type: catalog.isOnline ? "online" : "in-person",
        targets: catalog?.targets,
        accessProfile: catalog?.accessProfile,
        slug: catalog.slug,
        setSeverityAlert,
        setOpenAlert,
        setMessageAlert,
        onClickFavorite: () =>
          handleFavoriteClick(catalog.slug, isFavorite ?? false),
        isFavorite,
      };
    }),
  };

  return <TopServices {...relevantServicesProps} />;
};
