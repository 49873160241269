import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import { useWebgate } from "@xvia/webgate-connect";
import classNames from "classnames";
import { FC, MouseEventHandler, useCallback, useMemo } from "react";
import { setFavorites } from "../../../apis/dashboard";
import { Info } from "../../info/info";
import { ServiceLevelBadge } from "../../service-level-badge/service-level-badge";

import "./listing-card.css";

export interface ListingCardProps {
  title: string;
  subtitle: string;
  focused?: boolean;
  isDigital?: boolean;
  cost?: string;
  disposition?: "line" | "card";
  onClick: MouseEventHandler;
  onClickOnlineService: MouseEventHandler;
  icon?: string;
  accessProfile?: string;
  isFavorite?: boolean;
  onClickFavorite?: MouseEventHandler;
  slug?: string;
  setSeverityAlert?: (severity: "success" | "error") => void;
  setOpenAlert?: (open: boolean) => void;
  setMessageAlert?: (message: string) => void;
}

export const ListingCard: FC<ListingCardProps> = ({
  title,
  subtitle,
  focused,
  disposition,
  isDigital,
  cost,
  onClick,
  onClickOnlineService,
  icon,
  accessProfile,
  isFavorite,
  onClickFavorite,
  slug,
  setSeverityAlert,
  setOpenAlert,
  setMessageAlert,
}) => {
  const { accessToken, userInfo } = useWebgate();

  const containerClassName = useMemo(() => {
    return classNames({
      "xvia-listing-card": true,
      "xvia-listing-card---focused": focused,
      "xvia-listing-card__line": disposition === "line",
    });
  }, [focused, disposition]);

  const onlineServiceClickHandler = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      onClickOnlineService(event);
    },
    [onClickOnlineService]
  );

  const handleFavoriteClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await setFavorites(accessToken, {
        slug,
        remove: isFavorite ?? false,
      });
      setSeverityAlert?.("success");
      setOpenAlert?.(true);
      setMessageAlert?.(
        isFavorite
          ? "Serviço removido dos favoritos"
          : "Serviço favoritado com sucesso"
      );
      onClickFavorite?.(e);
    } catch (error) {
      console.error("Erro ao atualizar favorito:", error);
      setSeverityAlert?.("error");
      setOpenAlert?.(true);
      setMessageAlert?.("Erro ao favoritar serviço");
    }
  };

  return (
    <button aria-label={title} onClick={onClick} className={containerClassName}>
      <div className="xvia-listing-card__header">
        <div className="xvia-listing-card__header-icon">
          <FontAwesomeIcon icon={`fa-solid ${icon}` as IconProp} />
        </div>
        <div className="xvia-listing-card__header-right">
          <div className="xvia-listing-card__badge-container">
            {userInfo && (
              <button
                onClick={handleFavoriteClick}
                className={`xvia-listing-card__favorite 
                  ${isFavorite ? "is-favorite" : ""}`}
                aria-label={
                  isFavorite
                    ? "Remover dos favoritos"
                    : "Adicionar aos favoritos"
                }
              >
                <FontAwesomeIcon icon="star" />
              </button>
            )}
            {!!accessProfile && (
              <div className="xvia-listing-card__header-badge">
                <ServiceLevelBadge level={accessProfile} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="xvia-listing-card__content">
        <Typography variant="h5" className="xvia-listing-card__title">
          {title}
        </Typography>
        <Typography variant="body1" className="xvia-listing-card__subtitle">
          {subtitle}
        </Typography>
        <div className="xvia-listing-card__bottom">
          {cost ? (
            <Typography variant="body1" className="xvia-listing-card__info">
              <Info text={cost} icon="circle-dollar" />
            </Typography>
          ) : null}
          {isDigital && (
            <Button
              variant="text"
              onClick={onlineServiceClickHandler}
              className={"xvia-listing-card__bottom-button"}
              startIcon={
                <FontAwesomeIcon
                  onClick={onlineServiceClickHandler}
                  icon={`fa-solid fa-right-to-bracket` as IconProp}
                />
              }
            >
              Acessar Serviço Digital
            </Button>
          )}
        </div>
      </div>
    </button>
  );
};
