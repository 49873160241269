import { Alert, Portal, Snackbar } from "@mui/material";
import { useWebgate } from "@xvia/webgate-connect";
import { FC, useCallback, useEffect, useState } from "react";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { FooterContainer } from "../modules/footer/footer.container";
import { HeaderGenericContainer } from "../modules/header-generic/header-generic.container";
import { ListContainer } from "../modules/list/list.container";
import { LoginAreaContainer } from "../modules/login-area/login-area.container";

export const List: FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [severityAlert, setSeverityAlert] = useState<"success" | "error">(
    "success"
  );
  const [favoriteList, setFavoriteList] = useState<string[]>([]);
  const { userInfo } = useWebgate();

  useEffect(() => {
    if (userInfo?.catalog_favorite) {
      setFavoriteList(JSON.parse(userInfo.catalog_favorite));
    }
  }, [userInfo]);

  const updateFavorites = useCallback((slug: string, remove: boolean) => {
    setFavoriteList((prevList) =>
      remove ? prevList.filter((item) => item !== slug) : [...prevList, slug]
    );
  }, []);

  return (
    <>
      <Portal>
        <Snackbar
          sx={{
            zIndex: 9999999,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openAlert}
          autoHideDuration={3000}
          onClose={() => setOpenAlert(false)}
        >
          <Alert
            onClose={() => setOpenAlert(false)}
            severity={severityAlert}
            sx={{ width: "100%" }}
          >
            {messageAlert}
          </Alert>
        </Snackbar>
      </Portal>

      <HeaderGenericContainer
        centerElement={<SearchBarContainer />}
        rightElement={<LoginAreaContainer variant="blue" />}
      />

      <ListContainer
        favoriteList={favoriteList}
        onFavoriteUpdate={updateFavorites}
        setSeverityAlert={setSeverityAlert}
        setOpenAlert={setOpenAlert}
        setMessageAlert={setMessageAlert}
      />

      <FooterContainer />
    </>
  );
};
