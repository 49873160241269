import axios, { AxiosError, AxiosResponse } from "axios";

export interface Paycheck {
  id: number;
  period: {
    month: string;
    year: string;
  };
  bond: string;
  pdfLink: string;
}

export interface PaycheckResponse {
  id: number;
  link: string;
  servidor: {
    documento: string;
    success: boolean;
    ultimaGeracao: string;
    qtdErros: number;
  };
  siglaUrl: string;
  periodo: string;
}

export interface CNH {
  dataValidadeCNH: string;
  numeroRegistro: number;
  categoriaCNH: string;
  pontuacao: number | null;
  processo?: any | null;
  registro?: number;
  dataEmissao?: string;
  bloqueioBca?: boolean;
  bloqueioCnhApreendida?: boolean;
  cnhVencida?: boolean;
}

export interface UserVehicle {
  anoModelo?: number;
  modelo?: string;
  placa?: string;
  licenciamentoAtrasado?: boolean;
  restricaoJudicial?: boolean;
  restricaoAdministrativa?: boolean;
  restricaoTributaria?: boolean;
  quantMultas?: number;
  marca?: string;
  anoLicenciamento?: string;
  renavam?: string;
  tipoVeiculoId: number;
  tipoVeiculo: "AUTOMOVEL" | "MOTOCICLETA" | (string & {});
}

const apiUrl = "/api/contracheque";
const axiosClient = axios.create({
  baseURL: apiUrl,
  timeout: 0,
  headers: {},
});

export async function getPaychecks(accessToken?: string): Promise<Paycheck[]> {
  const response = await axiosClient.get<
    AxiosError,
    AxiosResponse<PaycheckResponse[]>
  >(`/sead/empresa/ultimos-contracheques`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.map((paycheck) => ({
    id: paycheck.id,
    bond: paycheck.siglaUrl.toUpperCase(),
    pdfLink: paycheck.link,
    period: {
      month: paycheck.periodo.split("/")[0],
      year: paycheck.periodo.split("/")[1],
    },
  }));
}
