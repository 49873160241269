import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Grid, Portal, Snackbar, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { FC, MouseEventHandler, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import striptags from "striptags";
import { postCatalogRating } from "../../apis/catalog-ratings";
import { CatalogDetail } from "../../apis/detail-catalog";
import { CatalogCard } from "../../components/cards/catalog-card/catalog-card";
import { SessionCard } from "../../components/cards/session-card/session-card";
import { SidebarCard } from "../../components/cards/sidebar-card/sidebar-card";
import { Container } from "../../components/container/container";
import { Info } from "../../components/info/info";
import { Step } from "../../components/step/step";
import { Tag } from "../../components/tag/tag";
import { Paragraph } from "../../components/typography/paragraph";
import "./catalog.css";

// @ts-ignore
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactHtmlParser from "react-html-parser";
import { Input } from "../../components/input/input";

export interface CatalogProps extends CatalogDetail {
  onPdfClick?: MouseEventHandler;
  onFavoriteClick?: MouseEventHandler;
  onServiceClick?: MouseEventHandler;
  searchServiceUnitsFilter: string;
  onSetSearchServiceUnitsFilter: (value: string) => void;
  isFavorite: boolean;
}

export const Catalog: FC<CatalogProps> = ({
  name,
  link,
  isFree,
  isOnline,
  isSelfService,
  resultCategory,
  accessProfile,
  updated_at,
  onPdfClick,
  onFavoriteClick,
  onServiceClick,
  description,
  searchServiceUnitsFilter,
  onSetSearchServiceUnitsFilter,
  info,
  steps,
  isFavorite,
  targets,
  tags,
  department,
  category,
  departmentSections,
  averageRatings,
  id,
  slug,
}) => {
  const [currentRatings, setCurrentRatings] = useState({
    count: 0,
    average: 5,
  });

  const [notification, setNotification] = useState<{
    message: string;
    severity: "success" | "error";
  } | null>(null);

  const handleCloseNotification = () => {
    setNotification(null);
  };

  const recalculateAverageRounded = (
    value: number,
    average: number,
    count: number
  ) => {
    const calculateAverage = (average * count + value) / (count + 1);

    return Math.round(calculateAverage);
  };

  const onSubmitRating = useCallback(
    (value: number) => {
      postCatalogRating(id, { value })
        .then(() => {
          setCurrentRatings(({ average, count }) => ({
            count: count + 1,
            average: recalculateAverageRounded(value, average, count),
          }));

          setNotification({
            message: "Avaliação registrada com sucesso!",
            severity: "success",
          });
        })
        .catch((err) => {
          let errorMessage = "Não foi possível completar a ação!";

          if (err?.response?.data?.status === 404) {
            errorMessage = "Recurso não encontrado!";
          }
          if (err?.response?.data?.status === 400) {
            errorMessage =
              err?.response?.data?.message ?? "Erro nos dados enviados!";
          }

          setNotification({ message: errorMessage, severity: "error" });
        });
    },
    [id]
  );

  useEffect(() => {
    if (averageRatings) {
      const { average, count } = averageRatings;
      setCurrentRatings({ average, count });
    }
  }, [averageRatings]);

  const pageTitle = name ? `Governo do Piauí - ${name}` : "Governo do Piauí";

  return (
    <div className="xvia-catalog">
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={striptags(description).substring(0, 100)}
        ></meta>
      </Helmet>
      <div className="xvia-catalog__hearder_container" />

      <Container className="xvia-catalog__container">
        <div className="">
          <CatalogCard
            title={name}
            isFree={isFree}
            isOnline={isOnline}
            isSelfService={isSelfService}
            resultCategory={resultCategory}
            lastUpdate={updated_at}
            rating={currentRatings.average}
            totalRatings={currentRatings.count}
            onPdfClick={onPdfClick}
            onFavoriteClick={onFavoriteClick}
            onServiceClick={onServiceClick}
            link={link}
            isFavorite={isFavorite}
            onRatingClick={onSubmitRating}
            slug={slug}
          />
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <div className="xvia-catalog__info_section">
              <Typography className="xvia-catalog__card_information_title">
                O que é esse serviço?
              </Typography>
              <Paragraph className="xvia-catalog__card_information_sub_title">
                {striptags(description)}
              </Paragraph>
            </div>

            {!!steps?.length && (
              <div className="xvia-catalog__info_section">
                <Typography className="xvia-catalog__card_information_title">
                  Etapas para a realização deste serviço
                </Typography>
                {steps?.map((step, index) => {
                  return (
                    <div key={step?.id} className={"xvia-catalog__info_row"}>
                      {steps[index - 1]?.channel !== step?.channel && (
                        <div style={{ fontSize: 20 }}>
                          {step?.channel === "online" ? (
                            <div className={"xvia-catalog__info_row_cannel"}>
                              Online <span>- Canal de atendimento digital</span>
                            </div>
                          ) : (
                            <div className={"xvia-catalog__info_row_cannel"}>
                              Presencial <span>- Unidades do governo</span>
                            </div>
                          )}
                        </div>
                      )}
                      <Step number={step.orderIndex} title={step?.name}>
                        <Paragraph className="xvia-catalog__card_information_sub_title">
                          {step?.description}
                        </Paragraph>
                        {step?.htmlContent && (
                          <div>{ReactHtmlParser(step?.htmlContent)}</div>
                        )}
                        <div>
                          {!!step.documents.length && (
                            <div>
                              <Typography className="xvia-catalog__card_information_title">
                                Documentos necessários
                              </Typography>
                              {step.documents.map((doc) => {
                                return (
                                  <div key={doc}>
                                    <Info text={doc} icon={"fa-check"} />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </Step>
                    </div>
                  );
                })}
              </div>
            )}
            {!!info?.length && (
              <div className="xvia-catalog__info_section">
                <Typography className=" xvia-catalog__card_information_title">
                  Informações
                </Typography>
                {info?.map((info) => {
                  return (
                    <Step key={info?.id} icon={info?.icon} title={info?.title}>
                      {ReactHtmlParser(info?.text)}
                    </Step>
                  );
                })}
              </div>
            )}
            {!!tags?.length && (
              <div className="xvia-catalog__info_section">
                <Typography className=" xvia-catalog__card_information_title">
                  TAGS
                </Typography>
                {tags?.map((tag) => {
                  return (
                    <Tag key={tag} href={"#"}>
                      {tag}
                    </Tag>
                  );
                })}
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={4} className="xvia-list_grid">
            {!!accessProfile && (
              <SidebarCard
                title={"Perfil de Acesso"}
                className="xvia-list__title"
                backgroundColor={`var(--xvia-color-${accessProfile.name.toLowerCase()})`}
              >
                <div className="xvia-list__content">
                  <Paragraph>
                    Este serviço é de classificação{" "}
                    <strong>{accessProfile.name}</strong>
                  </Paragraph>
                </div>
              </SidebarCard>
            )}

            <SidebarCard
              title={"Órgão responsável"}
              className="xvia-list__title"
              backgroundColor="var(--xvia-color-green)"
            >
              <div className="xvia-list__content">
                <Paragraph className="xvia-catalog__card_information_sub_title">
                  <FontAwesomeIcon
                    icon={faBuildingColumns}
                    style={{ marginRight: "15px", width: "15px" }}
                  />
                  {department?.shortName} - {department?.name}
                </Paragraph>
              </div>
            </SidebarCard>

            <SidebarCard
              title={"Unidades de atendimento"}
              className="xvia-list__title"
              backgroundColor="var(--xvia-color-yellow)"
            >
              {departmentSections?.length ? (
                <div className="xvia-list__content">
                  <div className="xvia-list__sidebar-filter-input">
                    <Input
                      title={"Palavra chave para categoria"}
                      onTextChange={onSetSearchServiceUnitsFilter}
                      value={searchServiceUnitsFilter}
                      placeholder="Filtrar unidades"
                      className="xvia-list__sidebar-input"
                    />
                    <span className="xvia-list__sidebar-icon">
                      <SearchOutlinedIcon />
                    </span>
                  </div>
                  <Paragraph>Unidades que prestam o serviço:</Paragraph>
                  {departmentSections
                    ?.filter(
                      (f) =>
                        f.name
                          .toLowerCase()
                          .indexOf(searchServiceUnitsFilter?.toLowerCase()) > -1
                    )
                    .map((department) => (
                      <div key={department?.id}>
                        <SessionCard
                          mapUrl={department?.mapUrl}
                          name={department?.name}
                          openingTime={department?.openingTime}
                          city={department?.city}
                          address={department?.address}
                          district={department?.district}
                          state={department?.state}
                        />
                      </div>
                    ))}
                </div>
              ) : (
                <div className="xvia-list__content">
                  <Paragraph>
                    Nenhuma unidade disponível para este serviço
                  </Paragraph>
                </div>
              )}
            </SidebarCard>

            <SidebarCard
              title={"Categoria"}
              className="xvia-list__title"
              backgroundColor="var(--xvia-color-red)"
            >
              <div className="xvia-list__content">
                <FontAwesomeIcon
                  icon={`fa-solid ${category?.icon}` as IconProp}
                  style={{ marginRight: "15px", width: 15 }}
                />
                {category?.name}
              </div>
            </SidebarCard>
          </Grid>
        </Grid>
      </Container>
      <Portal>
        <Snackbar
          sx={{ zIndex: 9999999 }}
          open={!!notification}
          autoHideDuration={3000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity={notification?.severity}
            onClose={handleCloseNotification}
          >
            {notification?.message}
          </Alert>
        </Snackbar>
      </Portal>
    </div>
  );
};
