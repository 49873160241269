import { FC, useCallback, useEffect, useLayoutEffect, useState } from "react";

import { useWebgate } from "@xvia/webgate-connect";
import { message } from "antd";
import { useParams } from "react-router-dom";
import { CatalogDetail, getCatalog } from "../../apis/detail-catalog";
import { getServerData } from "../../util/get-server-data";
import { getDateVerbose as getMaskDateVerbose } from "../../util/mask";
import { Catalog, CatalogProps } from "./catalog";

const portalUrl = process.env.PORTAL_URL;
const domain = portalUrl?.replace("https://", "")?.replace("http://", "");

const getDateVerbose = (date?: string) =>
  date && getMaskDateVerbose(new Date(date).getTime());

export const CatalogContainer: FC = () => {
  const { accessToken, portalConnect, serverData, userInfo } = useWebgate();
  const [catalogDetail, setCatalogDetail] = useState<CatalogDetail>();
  const [searchServiceUnitsFilter, setSearchServiceUnitsFilter] =
    useState<string>("");
  const { catalog } = useParams<{ catalog: string }>();
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.catalog_favorite) {
        let favoriteList = JSON.parse(userInfo?.catalog_favorite);
        setIsFavorite(favoriteList.includes(catalogDetail?.slug));
      }
    }
  }, [setIsFavorite, userInfo]);

  const getDetail = useCallback(
    (slug: string) => {
      console.log("[getDetail] Starting catalog fetch for slug:", slug);
      console.log("[getDetail] Using access token:", !!accessToken);

      getCatalog(accessToken, slug)
        .then((response) => {
          console.log("[getDetail] Successfully retrieved catalog:", {
            name: response.name,
            id: response.id,
            department: response.department?.name,
            hasRawData: !!response.catalogDataRow?.length,
            catalogDataRow: response.catalogDataRow,
          });
          setCatalogDetail(response);
        })
        .catch((error) => {
          console.error("[getDetail] Failed to fetch catalog:", error);
          message.error("Não foi possível carregar as informações do serviço!");
        });
    },
    [accessToken]
  );

  const openLink = useCallback(
    (url) => {
      console.log("[openLink] Starting with URL:", url);

      if (!url) {
        console.log("[openLink] No URL provided, returning");
        return;
      }

      const isPortalAppRegex = new RegExp(`^(https?://.*${domain})?/app/`);
      const isExternalMtGovPageRegex = new RegExp(`^(https?://.*mt.gov.br).*`);

      console.log("[openLink] Checking URL patterns:", {
        isPortalApp: isPortalAppRegex.test(`${portalUrl}/app/`),
        isAppPath: url.startsWith("/app/"),
        isExternalMtGov: isExternalMtGovPageRegex.test(url),
      });

      if (
        !isPortalAppRegex.test(`${portalUrl}/app/`) &&
        !url.startsWith("/app/")
      ) {
        console.log("[openLink] Opening external URL in new tab");
        window.open(url, "_blank");
        return;
      }

      if (isExternalMtGovPageRegex.test(url)) {
        console.log("[openLink] Opening mt.gov.br URL in new tab");
        window.open(url, "_blank");
        return;
      }

      if (catalogDetail?.catalogDataRow?.length) {
        const form = document.createElement("form");
        form.method = "POST"; // Garante que seja POST
        form.action = url;
        form.style.display = "none";

        catalogDetail.catalogDataRow.forEach(({ name, value }) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = name;
          input.value = value;
          form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();
        return;
      }

      //window.open(url, "_self");
      portalConnect?.redirectTo(url);
    },
    [portalConnect, catalogDetail]
  );

  useLayoutEffect(() => {
    console.log("[useLayoutEffect] Starting catalog detail check");
    console.log("[useLayoutEffect] Current catalog slug:", catalog);

    const catalogDetailFromCache =
      serverData?.catalogDetail ?? getServerData("catalogDetail", null);

    console.log("[useLayoutEffect] Cache data found:", {
      fromServer: !!serverData?.catalogDetail,
      fromLocalStorage: !!getServerData("catalogDetail", null),
      cacheSlug: catalogDetailFromCache?.slug,
    });

    if (catalogDetailFromCache?.slug === catalog) {
      console.log("[useLayoutEffect] Using cached catalog detail");
      setCatalogDetail(catalogDetailFromCache);
      return;
    }

    console.log("[useLayoutEffect] Cache miss, fetching from API");
    getDetail(catalog);
    console.log("[useLayoutEffect] Catalog detail fetched:", catalogDetail);
  }, [catalog, getDetail, serverData]);

  const props: CatalogProps = {
    ...(catalogDetail as CatalogProps),
    isFavorite,
    updated_at: getDateVerbose(catalogDetail?.updated_at),

    onPdfClick: () => {
      window.open(`/v1/catalog/${catalogDetail?.slug}/pdf`, "_blank");
    },
    onServiceClick: () => {
      openLink(catalogDetail?.link);
    },
    onFavoriteClick: () => {
      setIsFavorite(!isFavorite);
    },
    searchServiceUnitsFilter,
    onSetSearchServiceUnitsFilter: setSearchServiceUnitsFilter,
  } as CatalogProps;

  return <Catalog {...props} />;
};
