/* eslint-disable array-callback-return */
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Box, Grid } from "@mui/material";
import { Category } from "@xvia/webgate-connect";
import { Empty, Pagination } from "antd";
import React, { FC, useCallback } from "react";
import { Helmet } from "react-helmet";
import striptags from "striptags";
import { IDepartment } from "../../apis/department";
import { Catalog } from "../../apis/search-catalog";
import { ListingCard } from "../../components/cards/listing-card/listing-card";
import { SidebarCard } from "../../components/cards/sidebar-card/sidebar-card";
import { Container } from "../../components/container/container";
import { Input } from "../../components/input/input";
import { Tag } from "../../components/tag/tag";
import "./list.css";

export interface ListProps {
  list?: Catalog[];
  categoryList?: Category[];
  departmentList?: IDepartment[];
  onClick: (categorySlug: string, slug: string) => void;
  onClickOnlineService: (link: string) => void;
  onClickSearchCategory: (slug: string) => void;
  onClickSearch: () => void;
  onSetSearchCategoryFilter: (value: string) => void;
  onSetSearchValue: (value: string) => void;
  onCategorySelect: (value: string) => void;
  onDepartmentClick: (value: string) => void;
  onPaginate: (page: any) => void;
  onClickClearFilter: () => void;
  onClickClearSearchCategory: () => void;
  searchTextFilter?: string;
  searchCategoryFilter: string;
  searchText?: string;
  categoryFilter?: string;
  category?: string;
  categoryTitle?: string;
  orgaoTitle?: string;
  orgaoSlug?: string;
  resultTotal?: number;
  currentPage?: number;
  prefix: string;
  startLink: React.AllHTMLAttributes<HTMLAnchorElement>;
  favoriteList?: string[];
  onFavoriteUpdate?: (slug: string, remove: boolean) => void;
  setSeverityAlert?: (severity: "success" | "error") => void;
  setOpenAlert?: (open: boolean) => void;
  setMessageAlert?: (message: string) => void;
}

export const List: FC<ListProps> = ({
  list,
  categoryList,
  departmentList,
  onClick,
  onClickOnlineService,
  onClickSearchCategory,
  onSetSearchCategoryFilter,
  searchCategoryFilter,
  onClickSearch,
  onSetSearchValue,
  onDepartmentClick,
  onPaginate,
  searchText,
  searchTextFilter,
  categoryTitle,
  orgaoTitle,
  resultTotal,
  currentPage,
  prefix,
  startLink,
  onClickClearFilter,
  onClickClearSearchCategory,
  favoriteList = [],
  onFavoriteUpdate,
  setSeverityAlert,
  setOpenAlert,
  setMessageAlert,
}) => {
  const getDescription = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const keywords = categoryList?.map((category) => category.name).join(", ");

  const prefixText = "Governo do Piauí";
  const pageTitle = categoryTitle
    ? `${prefixText} - ${categoryTitle}`
    : `${prefixText} - Lista de Serviços`;

  const categoryClickHandler = useCallback(
    (slug: string) => {
      return () => {
        onClickSearchCategory(slug);
      };
    },
    [onClickSearchCategory]
  );

  return (
    <div className="xvia-list">
      <Helmet>
        <title>{pageTitle || "Governo do Piauí - Lista de Serviços"}</title>
        <meta
          name="description"
          content={getDescription(pageTitle || "", 100)}
        />
        {keywords && <meta name="keywords" content={keywords} />}
      </Helmet>
      <div className={"xvia-list__head_content"}>
        <Container className={"xvia-list__head_info_container"}>
          <h2 className={"xvia-list__head_info__title"}>
            {!!searchText && (
              <span>
                {startLink} {prefix} {searchText}
                {!!categoryTitle && <span> / {categoryTitle}</span>}
              </span>
            )}
            {!!categoryTitle && !searchText && (
              <span>
                {startLink} / {categoryTitle}
              </span>
            )}
            {!!orgaoTitle && (
              <span>
                {startLink} / {orgaoTitle}
              </span>
            )}
          </h2>
        </Container>
      </div>
      <Container className="xvia-list__container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sm={12} lg={4} xl={4}>
            <SidebarCard
              title={"Filtro"}
              className="xvia-list__title"
              backgroundColor="var(--xvia-color-yellow)"
            >
              <Box className="xvia-list__content">
                <div className="xvia-list__sidebar-filter-input">
                  <Input
                    title={"Palavra chave"}
                    onTextChange={onSetSearchValue}
                    value={searchTextFilter}
                    onEnterPress={onClickSearch}
                    placeholder="Buscar por palavra-chave"
                    className="xvia-list__sidebar-input"
                  />
                  <span
                    className="xvia-list__sidebar-icon"
                    style={{ display: searchTextFilter ? "" : "none" }}
                    onClick={onClickClearFilter}
                  >
                    <CloseOutlinedIcon />
                  </span>
                </div>
              </Box>
            </SidebarCard>

            <SidebarCard
              title={"Por Categoria"}
              className="xvia-list__title"
              backgroundColor="var(--xvia-color-red)"
            >
              <Box className="xvia-list__content">
                <div className="xvia-list__sidebar-filter-input">
                  {categoryTitle ? (
                    <>
                      <div className="xvia-list__sidebar-selected-category">
                        {categoryTitle}
                        <CloseIcon
                          className="xvia-list__sidebar-selected-category-icon"
                          onClick={onClickClearSearchCategory}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <Input
                        title={"Palavra chave para categoria"}
                        onTextChange={onSetSearchCategoryFilter}
                        value={searchCategoryFilter}
                        placeholder="Filtra categoria"
                        className="xvia-list__sidebar-input"
                      />
                      <span className="xvia-list__sidebar-icon">
                        <SearchOutlinedIcon />
                      </span>
                    </>
                  )}
                </div>
                {categoryList
                  ?.filter(
                    (f) =>
                      f.name
                        .toLowerCase()
                        .indexOf(searchCategoryFilter?.toLowerCase()) > -1
                  )
                  .map((category) => (
                    <div
                      className="xvia-list__sidebar-category"
                      onClick={categoryClickHandler(category.slug)}
                    >
                      <div className="xvia-list__sidebar-category-icon">
                        <FontAwesomeIcon
                          icon={`fa-solid ${category.icon}` as IconProp}
                        />
                      </div>
                      {category.name}
                    </div>
                  ))}
              </Box>
            </SidebarCard>

            <SidebarCard
              title={"Por Órgão"}
              className="xvia-list__title"
              backgroundColor="var(--xvia-color-green)"
            >
              <Box className="xvia-list__content">
                <div>
                  {departmentList?.map((department) => {
                    if (department?.active) {
                      return (
                        <span
                          onClick={() => {
                            onDepartmentClick(department.slug);
                          }}
                          key={department.id}
                        >
                          <Tag href={"#"}>{department.shortName}</Tag>
                        </span>
                      );
                    }
                  })}
                </div>
              </Box>
            </SidebarCard>
          </Grid>
          <Grid item xs={12} md={8} sm={12} lg={8} xl={8}>
            <Grid
              container
              xs={12}
              spacing={3}
              sx={{ maxWidth: "unset !important" }}
            >
              {list?.map((catalog) => {
                const isFavorite = favoriteList?.some(
                  (favorite) => favorite === catalog.slug
                );
                return (
                  <Grid item xs={12} sm={6} key={catalog.title}>
                    <ListingCard
                      icon={catalog.icon}
                      title={catalog.title}
                      subtitle={striptags(catalog.description)}
                      onClick={() => {
                        onClick(catalog.categorySlug, catalog.slug);
                      }}
                      onClickOnlineService={() => {
                        onClickOnlineService(catalog.link);
                      }}
                      disposition={"card"}
                      isDigital={catalog.isDigital}
                      accessProfile={catalog.accessProfile}
                      isFavorite={isFavorite}
                      onClickFavorite={() =>
                        onFavoriteUpdate?.(catalog.slug, isFavorite)
                      }
                      setSeverityAlert={setSeverityAlert}
                      setOpenAlert={setOpenAlert}
                      setMessageAlert={setMessageAlert}
                      slug={catalog.slug}
                    />
                  </Grid>
                );
              })}
            </Grid>
            {!list?.length && (
              <Empty description={"Nenhum serviço encontrado"} />
            )}
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={resultTotal}
              onChange={onPaginate}
              style={{ margin: "40px 0" }}
              showSizeChanger={false}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
