import { useWebgate } from "@xvia/webgate-connect";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getRecommendedServices,
  getRelevant,
} from "../../apis/relevant-catalog";
import { Catalog } from "../../apis/search-catalog";
import { getServerData } from "../../util/get-server-data";
import { RelevantServices, RelevantServicesProps } from "./relevant-services";

export interface RelevantServicesContainerProps {
  allCatalogList?: Catalog[];
  favoriteList?: string[];
  onFavoriteUpdate?: (slug: string, remove: boolean) => void;
  setMessageAlert?: (message: string) => void;
  setSeverityAlert?: (severity: "success" | "error") => void;
  setOpenAlert?: (open: boolean) => void;
}

export const RelevantServicesContainer: FC<RelevantServicesContainerProps> = ({
  allCatalogList,
  favoriteList,
  onFavoriteUpdate,
  setMessageAlert,
  setSeverityAlert,
  setOpenAlert,
}) => {
  const { accessToken, serverData, userInfo } = useWebgate();
  let history = useHistory();

  const [relevantCatalogList, setRelevantCatalogList] = useState<Catalog[]>(
    serverData?.relevant || getServerData("relevant", null)
  );

  const [isLoading, setIsLoading] = useState(false);

  const getServerRelevant = useCallback(() => {
    setIsLoading(true);
    if (!userInfo || Object.keys(userInfo).length === 0) {
      getRelevant(accessToken)
        .then((regularServices) => {
          setRelevantCatalogList(regularServices);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
      return;
    } else {
      getRecommendedServices(userInfo?.preferred_username)
        .then((recommendedResult) => {
          if (recommendedResult.recommended_services?.length > 0) {
            setRelevantCatalogList(recommendedResult.recommended_services);
            setIsLoading(false);
          } else {
            return getRelevant(accessToken);
          }
        })
        .then((regularServices) => {
          if (regularServices) {
            setRelevantCatalogList(regularServices);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          getRelevant(accessToken)
            .then((regularServices) => {
              setRelevantCatalogList(regularServices);
            })
            .catch((fallbackError) => {
              console.error("Error fetching fallback services:", fallbackError);
            })
            .finally(() => {
              setIsLoading(false);
            });
        });
    }
  }, [accessToken, userInfo]);

  useEffect(() => {
    getServerRelevant();
  }, [getServerRelevant]);

  const handleFavoriteClick = useCallback(
    (slug: string, isFavorite: boolean) => {
      onFavoriteUpdate?.(slug, isFavorite);
    },
    [onFavoriteUpdate]
  );

  const onClickCard = useCallback(
    (catalog: Catalog) => {
      if (catalog.link.startsWith("http")) {
        window.location.href = catalog.link;
      } else if (catalog.link.startsWith("/app/")) {
        window.location.href = catalog.link;
      } else {
        history.push(`${catalog.categorySlug}/${catalog.slug}`);
      }
    },
    [history]
  );

  const relevantServicesProps: RelevantServicesProps = {
    services: relevantCatalogList?.map((catalog) => {
      const isFavorite = favoriteList?.some(
        (favorite) => favorite === catalog.slug
      );
      return {
        title: catalog.title,
        description: catalog.description,
        categoryIcon: catalog.icon,
        onClick: () => onClickCard(catalog),
        imageUrl: catalog.imageUrl,
        type: catalog.isOnline ? "online" : "in-person",
        targets: catalog.targets,
        accessProfile: catalog.accessProfile,
        onClickFavorite: () =>
          handleFavoriteClick(catalog.slug, isFavorite ?? false),
        isFavorite,
        setSeverityAlert,
        setOpenAlert,
        slug: catalog.slug,
        setMessageAlert,
      };
    }),
  };

  return <RelevantServices {...relevantServicesProps} />;
};
